import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Base64 {
  static encodeBase64(v: string | Uint8Array): string {
    // eslint-disable-next-line no-undef
    return Buffer.from(v).toString('base64');
  }

  static decodeBase64(v: string): Uint8Array {
    return Base64.bufferToUint8Array(Buffer.from(v, 'base64'));
  }

  private static bufferToUint8Array(v: Buffer): Uint8Array {
    const buf = new Uint8Array(v.length);
    for (let i = 0; i < v.length; i += 1) {
      buf[i] = v[i];
    }
    return buf;
  }
}
