// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    appName: require('../../package.json').name,
    appVersion: require('../../package.json').version,
    hostAdminApiVersion: 'v2.0',
    hostLoginApiVersion: 'v2.0',
    actionsApiVersion: 'v2.0',
    loggingApiVersion: 'v1.0',
    paApiVersion: 'v1.0',
    /*
    production: false,
    API_BASE_PATH: 'https://dev.keyin.pro/api',
    RTMP_URL: 'rtmp://dev.keyin.pro:31000/live/',
    eDocHelperVideo: 'https://dev.keyin.pro/videos/VideoEDocReader.mp4',
    /*///*
    production: true,
    API_BASE_PATH: 'https://prod.keyin.pro/api',
    RTMP_URL: 'rtmp://prod.keyin.pro:32500/live/',
    eDocHelperVideo: 'https://prod.keyin.pro/videos/VideoEDocReader.mp4',
    /**/
    privacyPolicy: 'https://keyin.pro/doc/informativa_privacy_host/',
    eula: 'https://keyin.pro/doc/terms_and_conditions_keyin_host/'
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
