import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { KeyinHostService } from 'app/services/keyin/keyin-host.service';
import { GlobalService } from 'app/services/global/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private keyinhost: KeyinHostService,
    private router: Router,
    private global: GlobalService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.keyinhost.getToken();
    console.log('auth guard load checking token: ' + JSON.stringify(token));
    if(token != null) {
        if (moment.utc(token.refreshExpiresAt).isBefore(moment())) {
            console.log('auth guard load: token expired');
            this.keyinhost.logMessage({method: 'AuthGuard.canLoad', input: token,
                            result: 'Token expired for [' + this.keyinhost.getHostId() + ']'}, 'error');
            this.global.errorToast('Session expired, please login again');
            this.keyinhost.setToken(null);
            // redirect to login page
            this.router.navigateByUrl('/login');
            return false;
        }
        console.log('auth guard load: token valid');
        return true;
    }
    else {
        console.log('auth guard load: token not found');
        this.global.errorToast('Session expired, please login again');
        // remove token from storage
        this.keyinhost.setToken(null);
        // redirect to login page
        this.router.navigateByUrl('/login');
        return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = this.keyinhost.getToken();
    console.log('auth guard activate checking token: ' + JSON.stringify(token));
    if(token != null) {
        console.log('auth guard activate: token expires at: ' + moment.utc(token.refreshExpiresAt) + ' now: ' + moment());
        if (moment.utc(token.refreshExpiresAt).isBefore(moment())) {
            console.log('auth guard activate: token expired');
            this.keyinhost.logMessage({method: 'AuthGuard.canActivate', input: token,
                    result: 'Token expired for [' + this.keyinhost.getHostId() + ']'}, 'error');
            this.global.errorToast('Session expired, please login again');
            this.keyinhost.setToken(null);
            // redirect to login page
            this.router.navigateByUrl('/login');
            return false;
        }
        console.log('auth guard activate: token valid');
        return true;
    }
    else {
        console.log('auth guard activate: token not found');
        this.global.errorToast('Session expired, please login again');
        // redirect to login page
        this.router.navigateByUrl('/login');
        return false;
    }
  }
}
