
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { LogData } from '../model/logData';

import { BASE_PATH, API_VERSION } from '../variables';
import { Configuration } from '../configuration';
import { Response } from '../model/models';


@Injectable()
export class DefaultService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    protected basePath = '';
    protected apiVersion = '';

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional()@Inject(API_VERSION) apiVersion: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (apiVersion) {
            this.apiVersion = apiVersion + '/';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * Log a message
     *
     * @param body Log details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logMessage(body: LogData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public logMessage(body: LogData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public logMessage(body: LogData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public logMessage(body: LogData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling logMessage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        headers = headers.set('uid', ''.concat((body.guestId?.toString() || ''), (body.hostId?.toString() || ''),
                                                body.id?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/logging/${this.apiVersion}log`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
     private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
