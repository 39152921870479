import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private localStorage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init().then( () => { console.log('Initialized storage service'); });
  }

  async init() {
    console.log('Initializing storage...');
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this.localStorage = await this.storage.create();
    console.log('Initialized storage: ' + this.localStorage);
    this.logValues();
  }

  public async set(key: string, value: any) {
    console.log('Storing [' + key + '] = [' + value + ']');
    await this.localStorage?.set(key, value);
  }

  public async get(key: string, defValue: any = null) {
    try {
        console.log('Retrieving [' + key + ']');
        const value = await this.localStorage?.get(key);
        console.log('Retrieved [' + key + '] = [' + value + ']');
        if (value === undefined) {
            return defValue;
        }
        return value;
    }
    catch(e) {
        console.log('Error reading property[' + key + ']', e);
    }
    return null;
  }

  public logValues() {
    this.localStorage.forEach((v, k, idx) => {
        console.log(`ITEM - ${k} = ${v} [${idx}]`);
      });
  }
}
