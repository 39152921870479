import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ReservationOCData } from '../model/reservationOCData';

import { BASE_PATH, API_VERSION } from '../variables';
import { Configuration } from '../configuration';
import { RoomEnablementData } from '../model/roomEnablementData';
import { RoomAssociationData } from '../model/roomAssociationData';
import { BookTimeOffset } from '../model/bookTimeOffset';
import { NewReservationData } from '../model/newReservationData';
import { ServiceAssociationData } from '../model/serviceAssociationData';
import { NotifyReservationData } from '../model/notifyReservationData';
import { Response, Paging } from '../../model';
import { EditGuestData } from '../model/editGuestData';
import { HostPropertyData } from '../model/hostPropertyData';
import { ReservationData } from '../model/reservationData';
import { RoomData } from '../model/roomData';
import { CityData } from '../model/cityData';
import { CountryData } from '../model/countryData';



@Injectable()
export class DefaultService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    protected basePath = '';
    protected apiVersion = '';

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional()@Inject(API_VERSION) apiVersion: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (apiVersion) {
            this.apiVersion = apiVersion + '/';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }


    /**
     * Find property data by property id
     *
     * @param propertyId Identifier of the property
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHostPropertyData(token: string, propertyId: number, observe?: 'body', reportProgress?: boolean): Observable<Response<HostPropertyData>>;
    public getHostPropertyData(token: string, propertyId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<HostPropertyData>>>;
    public getHostPropertyData(token: string, propertyId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<HostPropertyData>>>;
    public getHostPropertyData(token: string, propertyId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling getHostPropertyData.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let params = new HttpParams();
        params = params.set('full', true);

        return this.httpClient.get<Response<HostPropertyData>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}`,
            {
                headers,
                params,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Find propertie's guest data by host id
     *
     * @param propertyId Identifier of the property
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPropertyReservationData(token: string, propertyId: number, pageNum: number, pageSize: number, query: string, manual: boolean, temporal: string, observe?: 'body', reportProgress?: boolean): Observable<Response<Paging<ReservationData>>>;
    public getPropertyReservationData(token: string, propertyId: number, pageNum: number, pageSize: number, query: string, manual: boolean, temporal: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<Paging<ReservationData>>>>;
    public getPropertyReservationData(token: string, propertyId: number, pageNum: number, pageSize: number, query: string, manual: boolean, temporal: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<Paging<ReservationData>>>>;
    public getPropertyReservationData(token: string, propertyId: number, pageNum: number, pageSize: number, query: string, manual: boolean, temporal: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling getPropertyGuestData.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let params = new HttpParams();
        if (pageNum !== null && pageNum !== undefined) {
            params = params.set('pageNum', pageNum);
        }
        if (pageSize !== null && pageSize !== undefined) {
            params = params.set('pageSize', pageSize);
        }
        if (query !== null && query !== undefined) {
            params = params.set('filter', query);
        }
        if (manual !== null && manual !== undefined) {
            params = params.set('manual', manual);
        }
        if (temporal !== null && temporal !== undefined) {
            params = params.set('temporal', temporal);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Response<Paging<ReservationData>>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations`,
            {
                headers,
                params,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find propertie's free rooms data by host id
     *
     * @param propertyId Identifier of the property
     * @param hostId Identifier of the host
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getFreeRoomPropertyData(token: string, propertyId: number, reservationId: string, observe?: 'body', reportProgress?: boolean): Observable<Response<Array<RoomData>>>;
     public getFreeRoomPropertyData(token: string, propertyId: number, reservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<Array<RoomData>>>>;
     public getFreeRoomPropertyData(token: string, propertyId: number, reservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<Array<RoomData>>>>;
     public getFreeRoomPropertyData(token: string, propertyId: number, reservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (propertyId === null || propertyId === undefined) {
             throw new Error('Required parameter propertyId was null or undefined when calling getFreeRoomPropertyData.');
         }
         if (reservationId === null || reservationId === undefined) {
             throw new Error('Required parameter reservationId was null or undefined when calling getFreeRoomPropertyData.');
         }

         let headers = this.defaultHeaders;
         headers = headers.set('Authorization', 'Bearer ' + token);

         // to determine the Accept header
         const httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected !== undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<Response<Array<RoomData>>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/rooms`,
             {
                 headers,
                 observe,
                 reportProgress
             }
         );
     }

    /**
     * Update reservation OC status
     *
     * @param ocData OC status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReservationOCStatus(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, ocData: ReservationOCData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public updateReservationOCStatus(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, ocData: ReservationOCData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public updateReservationOCStatus(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, ocData: ReservationOCData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public updateReservationOCStatus(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, ocData: ReservationOCData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling updateReservationOCStatus.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling updateReservationOCStatus.');
        }
        if (guestId === null || guestId === undefined) {
            throw new Error('Required parameter guestId was null or undefined when calling updateReservationOCStatus.');
        }
        if (ocData === null || ocData === undefined) {
            throw new Error('Required parameter ocData was null or undefined when calling updateReservationOCStatus.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(), (guestId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/guests/${guestId}/ocStatus`,
            ocData,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to manage Room Status
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public manageRoomStatus(token: string, hostId: number, propertyId: number, roomId: number, body: RoomEnablementData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public manageRoomStatus(token: string, hostId: number, propertyId: number, roomId: number, body: RoomEnablementData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public manageRoomStatus(token: string, hostId: number, propertyId: number, roomId: number, body: RoomEnablementData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public manageRoomStatus(token: string, hostId: number, propertyId: number, roomId: number, body: RoomEnablementData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling manageRoomStatus.');
        }
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling manageRoomStatus.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling manageRoomStatus.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (roomId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/rooms/${roomId}/status`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to associate room and guest
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associateRoomToGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: RoomAssociationData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public associateRoomToGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: RoomAssociationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public associateRoomToGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: RoomAssociationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public associateRoomToGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: RoomAssociationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling associateRoomToGuest.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling associateRoomToGuest.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling associateRoomToGuest.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/rooms`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to update reservation date/time
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, body: BookTimeOffset, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public updateReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, body: BookTimeOffset, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public updateReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, body: BookTimeOffset, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public updateReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, body: BookTimeOffset, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling updateReservationDateTime.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling updateReservationDateTime.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateReservationDateTime.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/dateTime`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to reset reservation date/time
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public resetReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public resetReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public resetReservationDateTime(token: string, hostId: number, propertyId: number, reservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling resetReservationDateTime.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling resetReservationDateTime.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.delete<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/dateTime`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * New reservation
     *
     * @param body reservation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newReservation(token: string, hostId: number, propertyId: number, body: NewReservationData, observe?: 'body', reportProgress?: boolean): Observable<Response<string>>;
    public newReservation(token: string, hostId: number, propertyId: number, body: NewReservationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<string>>>;
    public newReservation(token: string, hostId: number, propertyId: number, body: NewReservationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<string>>>;
    public newReservation(token: string, hostId: number, propertyId: number, body: NewReservationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling newReservation.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling newReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (body.channelId?.toString() || ''), (body.numGuests?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<string>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Change reservation
     *
     * @param body reservation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NewReservationData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public updateReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NewReservationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public updateReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NewReservationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public updateReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NewReservationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling updateReservation.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling updateReservation.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId.toString() || ''), (propertyId.toString() || ''),
                                               (body.channelId?.toString() || ''), body.reservationId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Delete reservation
     *
     * @param body reservation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReservation(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public deleteReservation(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public deleteReservation(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public deleteReservation(token: string, hostId: number, propertyId: number, reservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling deleteReservation.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling deleteReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               reservationId?.toString(), Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.delete<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Get guest
     *
     * @param guestId guest identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'body', reportProgress?: boolean): Observable<Response<EditGuestData>>;
    public getGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<EditGuestData>>>;
    public getGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<EditGuestData>>>;
    public getGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling getGuest.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling getGuest.');
        }
        if (guestId === null || guestId === undefined) {
            throw new Error('Required parameter guestId was null or undefined when calling getGuest.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Response<EditGuestData>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/guests/${encodeURIComponent(String(guestId))}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * New guest
     *
     * @param body guest details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: EditGuestData, observe?: 'body', reportProgress?: boolean): Observable<Response<number>>;
    public newGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: EditGuestData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<number>>>;
    public newGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: EditGuestData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<number>>>;
    public newGuest(token: string, hostId: number, propertyId: number, reservationId: string, body: EditGuestData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling newGuest.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling newGuest.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling newGuest.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (body.contactId?.toString() || ''), reservationId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<number>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/guests`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Change guest
     *
     * @param body guest details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, body: EditGuestData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public updateGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, body: EditGuestData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public updateGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, body: EditGuestData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public updateGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, body: EditGuestData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling updateGuest.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling updateGuest.');
        }
        if (guestId === null || guestId === undefined) {
            throw new Error('Required parameter guestId was null or undefined when calling updateGuest.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGuest.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (body.contactId?.toString() || ''), (guestId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/guests/${guestId}`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Delete guest
     *
     * @param body guest details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public deleteGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public deleteGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public deleteGuest(token: string, hostId: number, propertyId: number, reservationId: string, guestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling deleteGuest.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling deleteGuest.');
        }
        if (guestId === null || guestId === undefined) {
            throw new Error('Required parameter guestId was null or undefined when calling deleteGuest.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(), (guestId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.delete<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/guests/${guestId}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Notify reservation guests
     *
     * @param body reservation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notifyReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NotifyReservationData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public notifyReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NotifyReservationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public notifyReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NotifyReservationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public notifyReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: NotifyReservationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling notifyReservation.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling notifyReservation.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling notifyReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId, body.guestId?.toString(),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/notify`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Request to associate service and reservation
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associateServiceToReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public associateServiceToReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public associateServiceToReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public associateServiceToReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling associateServiceToReservation.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling associateServiceToReservation.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling associateServiceToReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(), (body.itemId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/services/${body.type}/${body.itemId}`,
            body,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Request to disassociate service and reservation
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disassociateServiceFromReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public disassociateServiceFromReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public disassociateServiceFromReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public disassociateServiceFromReservation(token: string, hostId: number, propertyId: number, reservationId: string, body: ServiceAssociationData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling disassociateServiceFromReservation.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling disassociateServiceFromReservation.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling disassociateServiceFromReservation.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                                reservationId?.toString(), (body.itemId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.delete<Response<null>>(`${this.basePath}/host/admin/${this.apiVersion}properties/${propertyId}/reservations/${encodeURIComponent(String(reservationId))}/services/${body.type}/${body.itemId}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Find countries by filter
     *
     * @param filter Filetr for selection
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountries(filter: string, observe?: 'body', reportProgress?: boolean): Observable<Response<Array<CountryData>>>;
    public getCountries(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<Array<CountryData>>>>;
    public getCountries(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<Array<CountryData>>>>;
    public getCountries(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            filter = '';
        }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Response<Array<CountryData>>>(`${this.basePath}/host/admin/${this.apiVersion}country?filter=${encodeURIComponent(filter)}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find cities by filter
     *
     * @param filter Filetr for selection
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCities(filter: string, observe?: 'body', reportProgress?: boolean): Observable<Response<Array<CityData>>>;
    public getCities(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<Array<CityData>>>>;
    public getCities(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<Array<CityData>>>>;
    public getCities(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getCities.');
        }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Response<Array<CityData>>>(`${this.basePath}/host/admin/${this.apiVersion}city?filter=${encodeURIComponent(filter)}`,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
