import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KeyinHostService } from 'app/services/keyin/keyin-host.service';
import { CountryData } from 'app/api/hostadmin/model/countryData';
import { Response } from 'app/api/model';
import { BaseGenericCallback } from 'app/utils/generic.interface';

@Component({
  selector: 'app-country-search-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Country</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-searchbar [(ngModel)]="searchText" (ionChange)="searchCountries()"></ion-searchbar>
      <ion-list>
        <ion-item *ngFor="let country of countries" (click)="selectCountry(country.iso3Code)">
          {{ country.name }}
        </ion-item>
      </ion-list>
    </ion-content>
  `
})
export class CountrySearchModalComponent implements OnInit {
  searchText = '';
  countries: CountryData[] = [];

  constructor(
    private modalCtrl: ModalController,
    private keyinhost: KeyinHostService
  ) {}

  ngOnInit() {}

  searchCountries() {
    console.log('sono entrato')
    if (this.searchText.trim().length < 3) {
      this.countries = [];
      return;
    }

    const self = this;

    this.keyinhost.getCountries(this.searchText.trim().toUpperCase(), new class extends BaseGenericCallback<Response<Array<CountryData>>> {
      constructor() {
        super(null); // Assuming 'null' is a valid argument for `page`
      }

      onSuccess(response: Response<Array<CountryData>>) {
        self.countries = response.data;
      }

      onFail(exc: any) {
        self.countries = [];
      }
    }());

  }

  selectCountry(iso3Code: string) {
    this.modalCtrl.dismiss(iso3Code);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
