import { Injectable } from '@angular/core';
import { EncodingService } from './encoding.service';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class TimeSignService {

    constructor(private encoder: EncodingService) { }

    /**
     * Encode and sign input <code>data</code>, the signature has a max validity of <code>ttl</code> seconds and is TimeZone dependent.
     *
     * @param data  the string to sign
     * @param ttl seconds of signature validity
     * @return a time signed/encoded data
     */
     public sign(data: string, ttl: number) {
        const now = moment().subtract(ttl, 'seconds'); // TODO: REMOVE THIS WORKAROUND
        const nowStr = now.format();

        const clear = data + '###' + nowStr + '#' + (2 * ttl);// TODO: REMOVE THIS WORKAROUND
        const enc = this.encoder.encode(clear);
        console.log('Signed [' + data + '] with a TTL of ' + ttl + ' seconds -> ' + enc);
        return enc;
    }
}
