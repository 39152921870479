import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Response } from '../../model/response';
import { HostData } from '../../model/hostData';

import { Login } from '../model/login';
import { ChangePassword } from '../model/changePassword';
import { ResetPassword } from '../model/resetPassword';
import { ChangePin } from '../model/changePin';
import { JWTToken } from '../model/jwtToken';
import { RefreshJWTToken } from '../model/refreshJWTToken';

import { BASE_PATH, API_VERSION, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class DefaultService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    protected basePath = '';
    protected apiVersion = '';

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional()@Inject(API_VERSION) apiVersion: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (apiVersion) {
            this.apiVersion = apiVersion + '/';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }

        console.log(this.configuration);
    }


    /**
     * Login an host
     *
     * @param login Host login data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(login: Login, observe?: 'body', reportProgress?: boolean): Observable<Response<JWTToken>>;
    public login(login: Login, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<JWTToken>>>;
    public login(login: Login, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<JWTToken>>>;
    public login(login: Login, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling loginPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Response<JWTToken>>(`${this.basePath}/host/auth/${this.apiVersion}login`,
            login,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Logout an host
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logout(token: string, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public logout(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public logout(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public logout(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<Response<null>>(`${this.basePath}/host/auth/${this.apiVersion}logout`,
            '',
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Refresh token
     *
     * @param token refresh token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshToken(refrToken: RefreshJWTToken, observe?: 'body', reportProgress?: boolean): Observable<Response<JWTToken>>;
    public refreshToken(refrToken: RefreshJWTToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<JWTToken>>>;
    public refreshToken(refrToken: RefreshJWTToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<JWTToken>>>;
    public refreshToken(refrToken: RefreshJWTToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refrToken === null || refrToken === undefined) {
            throw new Error('Required parameter refrToken was null or undefined when calling refreshToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Response<JWTToken>>(`${this.basePath}/host/auth/${this.apiVersion}refreshToken`,
            refrToken,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Check a host login session"
     * Returns the identifier of the host
     *
     * @param hostId ID of host
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHost(token: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<Response<HostData>>;
    public getHost(token: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<HostData>>>;
    public getHost(token: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<HostData>>>;
    public getHost(token: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       if (email === null || email === undefined) {
           throw new Error('Required parameter email was null or undefined when calling checkSession.');
       }

       let headers = this.defaultHeaders;
       headers = headers.set('Authorization', 'Bearer ' + token);

       // to determine the Accept header
       const httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected !== undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       let params = new HttpParams();
       params = params.set('full', true);

       return this.httpClient.get<Response<HostData>>(`${this.basePath}/host/auth/${this.apiVersion}getHost/${encodeURIComponent(email)}`,
           {
               headers,
               params,
               observe,
               reportProgress
           }
       );
    }

    /**
     * Register an host login data
     * Insert a new host for a given property. Before the insert it checks if the property is in the database
     *
     * @param register Host login details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerHost(token: string, register: HostData, observe?: 'body', reportProgress?: boolean): Observable<Response<HostData>>;
    public registerHost(token: string, register: HostData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<HostData>>>;
    public registerHost(token: string, register: HostData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<HostData>>>;
    public registerHost(token: string, register: HostData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (register === null || register === undefined) {
            throw new Error('Required parameter register was null or undefined when calling registerHostLogin.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Response<HostData>>(`${this.basePath}/host/auth/${this.apiVersion}register`,
            register,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Allows an host to change his/her password
     *
     * @param login Host login details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePassword(token: string, hostId: number, chgPwd: ChangePassword, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public changePassword(token: string, hostId: number, chgPwd: ChangePassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public changePassword(token: string, hostId: number, chgPwd: ChangePassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public changePassword(token: string, hostId: number, chgPwd: ChangePassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling changePassword.');
        }
        if (chgPwd === null || chgPwd === undefined) {
            throw new Error('Required parameter chgPwd was null or undefined when calling changePassword.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/auth/${this.apiVersion}${encodeURIComponent(hostId)}/changePassword`,
            chgPwd,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Allows an host to reset his/her password
     *
     * @param reset Host login details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(resetPwd: ResetPassword, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public resetPassword(resetPwd: ResetPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public resetPassword(resetPwd: ResetPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public resetPassword(resetPwd: ResetPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (resetPwd === null || resetPwd === undefined) {
            throw new Error('Required parameter resetPwd was null or undefined when calling resetPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/auth/${this.apiVersion}resetPassword`,
            resetPwd,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Allows an host to change his/her PIN
     *
     * @param chgPin Host PIN details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePin(token: string, hostId: number, chgPin: ChangePin, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public changePin(token: string, hostId: number, chgPin: ChangePin, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public changePin(token: string, hostId: number, chgPin: ChangePin, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public changePin(token: string, hostId: number, chgPin: ChangePin, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chgPin === null || chgPin === undefined) {
            throw new Error('Required parameter login was null or undefined when calling changePin.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Response<null>>(`${this.basePath}/host/auth/${this.apiVersion}${encodeURIComponent(hostId)}/changePin`,
            chgPin,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
}
