import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Config {
    static props: any;

    static setProps(props: any) {
        this.props = props;
    }

    static getString(name: string, defValue: string): string {
        if (this.props === undefined) {
            return defValue;
        }
        let value = this.props[name];
        if (value === undefined) {
            value = defValue;
        }
        return value;
    }

    static getInt(name: string, defValue: number): number {
        if (this.props === undefined) {
            return defValue;
        }
        const value = this.props[name];
        if (value === undefined) {
            return defValue;
        }
        return parseInt(value, 10);
    }

    static getFloat(name: string, defValue: number): number {
        if (this.props === undefined) {
            return defValue;
        }
        const value = this.props[name];
        if (value === undefined) {
            return defValue;
        }
        return parseFloat(value);
    }

    static getBoolean(name: string, defValue: boolean): boolean {
        if (this.props === undefined) {
            return defValue;
        }
        const value = this.props[name];
        if (value === undefined) {
            return defValue;
        }
        return 'Y' === value;
    }
}
