/**
 * Swagger HostLogin
 * This is the API that allows OCSI host aplication to manage login on OCSI back-end
 *
 * OpenAPI spec version: 1.0.0
 * Contact: ocsi.project@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface HostLogin {
    email?: string;
    password?: string;
}
