import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
    canLoad: [AuthGuard]
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'resource',
    loadChildren: () => import('./pages/resource/resource.module').then( m => m.ResourcePageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'actionlogs',
    loadChildren: () => import('./pages/action-logs/action-logs.module').then( m => m.ActionLogPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'changepwd',
    loadChildren: () => import('./pages/login/change-password/changepwd.module').then( m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'changepin',
    loadChildren: () => import('./pages/login/change-pin/changepin.module').then( m => m.ChangePinPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'resetpwd',
    loadChildren: () => import('./pages/login/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/login/logout/logout.module').then( m => m.LogoutPageModule)
    //,
    //canLoad: [AuthGuard], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
