import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BASE_PATH, API_VERSION } from '../variables';
import { Configuration } from '../configuration';
import { Response } from '../../model';
import { PortaleAlloggiatiRunData } from '../model/portaleAlloggiatiRunData';


@Injectable()
export class DefaultService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    protected basePath = '';
    protected apiVersion = '';

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional()@Inject(API_VERSION) apiVersion: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (apiVersion) {
            this.apiVersion = apiVersion + '/';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }


    /**
     * Find portale alloggiati data by property id and reference date
     *
     * @param propertyId Identifier of the property
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listaInvii(token: string, propertyId: number, referenceDate: string, observe?: 'body', reportProgress?: boolean): Observable<Response<Array<PortaleAlloggiatiRunData>>>;
    public listaInvii(token: string, propertyId: number, referenceDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<Array<PortaleAlloggiatiRunData>>>>;
    public listaInvii(token: string, propertyId: number, referenceDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<Array<PortaleAlloggiatiRunData>>>>;
    public listaInvii(token: string, propertyId: number, referenceDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling getRunList.');
        }
        if (referenceDate === null || referenceDate === undefined) {
            throw new Error('Required parameter referenceDate was null or undefined when calling getRunList.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let params = new HttpParams();
        params = params.set('full', true);

        return this.httpClient.get<Response<Array<PortaleAlloggiatiRunData>>>(`${this.basePath}/portalealloggiati/${this.apiVersion}lista/${propertyId}/${encodeURIComponent(String(referenceDate))}`,
            {
                headers,
                params,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to process a single reservation
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public preparaSingoloInvio(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'body', reportProgress?: boolean): Observable<Response<PortaleAlloggiatiRunData>>;
    public preparaSingoloInvio(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<PortaleAlloggiatiRunData>>>;
    public preparaSingoloInvio(token: string, hostId: number, propertyId: number, reservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<PortaleAlloggiatiRunData>>>;
    public preparaSingoloInvio(token: string, hostId: number, propertyId: number, reservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling preparaSingoloInvio.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling preparaSingoloInvio.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (reservationId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<PortaleAlloggiatiRunData>>(`${this.basePath}/portalealloggiati/${this.apiVersion}preparaSingolo/${propertyId}/${encodeURIComponent(String(reservationId))}`,
            null,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to process all reservation to reference date
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public preparaInvio(token: string, hostId: number, propertyId: number, referenceDate: string, observe?: 'body', reportProgress?: boolean): Observable<Response<PortaleAlloggiatiRunData>>;
    public preparaInvio(token: string, hostId: number, propertyId: number, referenceDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<PortaleAlloggiatiRunData>>>;
    public preparaInvio(token: string, hostId: number, propertyId: number, referenceDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<PortaleAlloggiatiRunData>>>;
    public preparaInvio(token: string, hostId: number, propertyId: number, referenceDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling preparaInvio.');
        }
        if (referenceDate === null || referenceDate === undefined) {
            throw new Error('Required parameter referenceDate was null or undefined when calling preparaInvio.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (referenceDate?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<PortaleAlloggiatiRunData>>(`${this.basePath}/portalealloggiati/${this.apiVersion}prepara/${propertyId}/${encodeURIComponent(String(referenceDate))}`,
            null,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to send to portale alloggiati a single reservation data
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviaWS(token: string, hostId: number, propertyId: number, runNumber: number, reservationId: string, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public inviaWS(token: string, hostId: number, propertyId: number, runNumber: number, reservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public inviaWS(token: string, hostId: number, propertyId: number, runNumber: number, reservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public inviaWS(token: string, hostId: number, propertyId: number, runNumber: number, reservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling inviaWS.');
        }
        if (runNumber === null || runNumber === undefined) {
            throw new Error('Required parameter runNumber was null or undefined when calling inviaWS.');
        }
        if (reservationId === null || reservationId === undefined) {
            throw new Error('Required parameter reservationId was null or undefined when calling inviaWS.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (runNumber?.toString() || ''), (reservationId?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/portalealloggiati/${this.apiVersion}inviaWS/${propertyId}/${runNumber}/${encodeURIComponent(String(reservationId))}`,
            null,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * Request to send to host a single run report email
     *
     * @param body Action details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviaEmail(token: string, hostId: number, propertyId: number, runNumber: number, observe?: 'body', reportProgress?: boolean): Observable<Response<null>>;
    public inviaEmail(token: string, hostId: number, propertyId: number, runNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Response<null>>>;
    public inviaEmail(token: string, hostId: number, propertyId: number, runNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Response<null>>>;
    public inviaEmail(token: string, hostId: number, propertyId: number, runNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (propertyId === null || propertyId === undefined) {
            throw new Error('Required parameter propertyId was null or undefined when calling inviaEmail.');
        }
        if (runNumber === null || runNumber === undefined) {
            throw new Error('Required parameter runNumber was null or undefined when calling inviaEmail.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', 'Bearer ' + token);

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        headers = headers.set('uid', ''.concat((hostId?.toString() || ''), (propertyId?.toString() || ''),
                                               (runNumber?.toString() || ''),
                                                Math.floor((new Date()).getTime() / 1000).toString()));

        return this.httpClient.post<Response<null>>(`${this.basePath}/portalealloggiati/${this.apiVersion}inviaEmail/${propertyId}/${runNumber}`,
            null,
            {
                headers,
                observe,
                reportProgress
            }
        );
    }


    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
