import { Role } from './role';

export class RoleChecker {
    private currentRole: Role;

    constructor(roleString: string) {
        this.currentRole = Role[roleString as keyof typeof Role];
    }

    /*public isRoleAllowed(roleString: string): boolean {
        const role = Role[roleString as keyof typeof Role];
        return role !== undefined && role <= this.currentRole;
    }*/

    public isRoleAllowed(role: Role): boolean {
        return role <= this.currentRole;
    }
}
