import { Component, Input, OnInit } from '@angular/core';
import { Response } from 'app/api/model';
import { HostPropertyData, NewReservationData } from 'app/api/hostadmin';
import { GlobalService } from 'app/services/global/global.service';
import { KeyinHostService } from 'app/services/keyin/keyin-host.service';
import { BaseGenericCallback } from 'app/utils/generic.interface';

import * as moment from 'moment';
import { ResourcePage } from 'app/pages/resource/resource.page';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ModalService } from 'app/services/global/modal.service';

@Component({
  selector: 'app-new-reservation',
  templateUrl: './new-reservation.component.html',
  styleUrls: ['./new-reservation.component.scss']
})
export class NewReservationComponent implements OnInit {

  @Input() property: HostPropertyData;
  @Input() parent: ResourcePage;

  reservationForm: FormGroup;

  formValue = {phoneNumber: '', test: ''};
  preferredCountries = ['it','gb','us'];

  langList = [
    {
        id: 'en', val: 'English'
    },
    {
        id: 'it', val: 'Italiano'
    }
  ];

  guestTypeList = [
    {
        id: 1, val: 'Single guest'
    },
    {
        id: 2, val: 'Family head'
    },
    {
        id: 3, val: 'Group head'
    },
    {
        id: 4, val: 'Family member'
    },
    {
        id: 5, val: 'Group member'
    }
  ];

  isSubmitted = false;

  constructor(private keyinhost: KeyinHostService,
    private global: GlobalService,
    private modalSvc: ModalService
  ) { }

  ngOnInit() {

    const today =  moment(new Date()).format('YYYY-MM-DD');
    const tomorrow =  moment(new Date()).add(1, 'day').format('YYYY-MM-DD');

    this.reservationForm = new FormGroup({
      guestType: new FormControl(1, Validators.required),
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, this.conditionalPhoneValidator()]),
      lang: new FormControl('en', Validators.required),
      checkIn: new FormControl(today, Validators.required),
      checkOut: new FormControl(tomorrow, Validators.required),
      numGuests: new FormControl(1, [Validators.required, Validators.min(1)]),
      channelId: new FormControl(0, Validators.required),
      reservationId: new FormControl('')
    });

  }

  async onSubmit() {
    if (this.reservationForm.valid) {
      this.reservationForm.value.phone = this.reservationForm.value.phone.e164Number;

      const reservation: NewReservationData = this.reservationForm.value;
      reservation.checkIn = moment(reservation.checkIn).format('YYYY-MM-DD');
      reservation.checkOut = moment(reservation.checkOut).format('YYYY-MM-DD');

      try {
        this.isSubmitted = true;
        this.keyinhost.newReservation(reservation, new class extends BaseGenericCallback<Response<string>> {
          onSuccess(response: Response<string>) {
              console.log('Created new reservation');
              this.page.modalSvc.modalDismiss();
              this.page.isSubmitted = false;
              this.page.global.successToast('Reservation created');
              this.page.parent.setMode('Book');
              this.page.parent.searchReservations(this.page.parent.searchBar);
          }
          onFail(exc: any) {
              console.log('Error creating reservation, ' + exc);
              this.page.keyinhost.logMessage({method: 'NewReservationComponent.onSubmit', input: { reservation },
                result: 'Errore creating reservation', error: exc}, 'error');
              this.page.modalSvc.modalDismiss();
              this.page.isSubmitted = false;

              let msg = 'Could not create reservation, please try again. [' + exc.status + ']';
              if (exc.status === 400) {
                  msg += '[Missing or invalid parameters]';
              }
              else if (exc.status === 409) {
                  msg += '[Guest or book ID already present]';
              }
              this.page.global.showAlert(msg, 'Error creating reservation');
          }
        }(this));
      } catch(e) {
        console.log('Error creating reservation', e);
        this.keyinhost.logMessage({method: 'NewReservationComponent.onSubmit', input: { reservation },
            result: 'Errore creating reservation', error: e}, 'error');
        this.global.errorToast();
      }
    }
  }

  close() {
    this.modalSvc.modalClose();
  }

  public minCheckInDate(): string {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  public maxCheckInDate(): string {
    return moment(new Date()).add(2, 'year').format('YYYY-MM-DD');
  }

  public minCheckOutDate(): string {
    return moment(new Date()).add(1, 'day').format('YYYY-MM-DD');
  }

  public maxCheckOutDate(): string {
    return moment(new Date()).add(2, 'year').format('YYYY-MM-DD');
  }

  onCheckInDateSelected(date: string) {
    this.reservationForm.get('checkIn').setValue(date);
  }

  onCheckOutDateSelected(date: string) {
    this.reservationForm.get('checkOut').setValue(date);
  }

  conditionalPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phone = control.value.nationalNumber;
      if (phone === null || phone === '') {
        return null; // Valid if empty
      }

      console.log('conditionalPhoneValidator: ' + phone + ' - ' + phone?.length);

      const error = { phone: true };
      let phoneNumber;
      try {
        phoneNumber = PhoneNumberUtil.getInstance().parse(control.value.nationalNumber, control.value.isoCode);
      }
      catch (e) {
        return error;
      }
      if (!phoneNumber) {
        return error;
      }
      if (!PhoneNumberUtil.getInstance().isPossibleNumber(phoneNumber)) {
        return error;
      }
    };
  }
}
