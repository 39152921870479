
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicSelectableModule } from 'ionic-selectable';

import { ApiModule as HostLoginApiModule, BASE_PATH as hl_BASE_PATH, API_VERSION as hl_API_VERSION } from './api/hostlogin';
import { ApiModule as HostAdminApiModule, BASE_PATH as ha_BASE_PATH, API_VERSION as ha_API_VERSION } from './api/hostadmin';
import { ApiModule as ActionsApiModule, BASE_PATH as act_BASE_PATH, API_VERSION as act_API_VERSION } from './api/actions';
import { ApiModule as LoggingApiModule, BASE_PATH as log_BASE_PATH, API_VERSION as log_API_VERSION } from './api/logging';
import { ApiModule as PortalealloggiatiApiModule, BASE_PATH as pa_BASE_PATH, API_VERSION as pa_API_VERSION } from './api/portalealloggiati';
import { StorageService } from './services/storage/storage.service';
import { EncodingService } from './services/utils/encoding.service';
import { TimeSignService } from './services/utils/timesign.service';
import { KeyinHostService } from './services/keyin/keyin-host.service';

import { NgxCieModule } from 'ngx-cie';

import { environment } from '../environments/environment';
import { NewReservationComponent } from './components/new-reservation/new-reservation.component';
import { NewGuestComponent } from './components/new-guest/new-guest.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonIntlTelInputModule } from 'ion-intl-tel-v2';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CountrySearchModalComponent } from './components/new-guest/CountrySearchModalComponent';
import { CitySearchModalComponent } from './components/new-guest/CitySearchModalComponent';
import { EditCountrySearchModalComponent } from './components/edit-guest/EditCountrySearchModalComponent';
import { EditCitySearchModalComponent } from './components/edit-guest/EditCitySearchModalComponent';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NewReservationComponent,
    NewGuestComponent,
    CountrySearchModalComponent,
    EditCountrySearchModalComponent,
    EditCitySearchModalComponent,
    CitySearchModalComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgxCieModule.forRoot(), BrowserModule, FormsModule,ReactiveFormsModule, IonicModule.forRoot(), AppRoutingModule,
    IonicSelectableModule, IonicStorageModule.forRoot(), HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HostLoginApiModule, HostAdminApiModule, ActionsApiModule, LoggingApiModule, PortalealloggiatiApiModule, IonIntlTelInputModule],
  entryComponents: [
      EditCountrySearchModalComponent,
      EditCitySearchModalComponent,
      CountrySearchModalComponent,
      CitySearchModalComponent
    ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ha_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: ha_API_VERSION, useValue: environment.hostAdminApiVersion },
    { provide: act_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: act_API_VERSION, useValue: environment.actionsApiVersion },
    { provide: hl_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: hl_API_VERSION, useValue: environment.hostLoginApiVersion },
    { provide: log_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: log_API_VERSION, useValue: environment.loggingApiVersion },
    { provide: pa_BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: pa_API_VERSION, useValue: environment.paApiVersion },
    StorageService, EncodingService, TimeSignService, KeyinHostService,
    InAppBrowser, EmailComposer],
  bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private storage: StorageService) {}
}
