import { ChangeDetectorRef, Component,Input, OnInit, OnDestroy} from '@angular/core';

import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Response } from 'app/api/model';
import { HostPropertyData, EditGuestData, ReservationData } from 'app/api/hostadmin';
import { GlobalService } from 'app/services/global/global.service';
import { KeyinHostService } from 'app/services/keyin/keyin-host.service';
import { BaseGenericCallback } from 'app/utils/generic.interface';

import { ResourcePage } from 'app/pages/resource/resource.page';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { format, formatISO, parse } from 'date-fns';
import { CieIdentity, ImageInfos, NgxCieService, NgxDecoderService, Util,ReadingSession } from 'ngx-cie';
import { Base64 } from 'app/utils/base64';
import { Md5 } from 'ts-md5/dist/md5';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CountrySearchModalComponent } from './CountrySearchModalComponent';
import { CitySearchModalComponent } from './CitySearchModalComponent';
import { ModalService } from 'app/services/global/modal.service';


@Component({
  selector: 'app-new-guest',
  templateUrl: './new-guest.component.html',
  styleUrls: ['./new-guest.component.scss']
})
export class NewGuestComponent implements OnInit, OnDestroy {

  @Input() property: HostPropertyData;
  @Input() parent: ResourcePage;
  @Input() reservationData: ReservationData;

  mode: string;

  showDocumentFields = false;

  guestForm: FormGroup;

  formValue = {phoneNumber: '', test: ''};
  preferredCountries = ['it','gb','us'];


  langList = [
    {
        id: 'en', val: 'English'
    },
    {
        id: 'it', val: 'Italiano'
    }
  ];

  guestTypeList = [
    {
        id: 1, val: 'Single guest'
    },
    {
        id: 2, val: 'Family head'
    },
    {
        id: 3, val: 'Group head'
    },
    {
        id: 4, val: 'Family member'
    },
    {
        id: 5, val: 'Group member'
    }
  ];

  isSubmitted = false;



  //CIE SCAN
  baseDate = parse('2000-01-01', 'yyyy-MM-dd', new Date());

  photoString = '';
  photoData = '';
  photoMime = '';

  nfcAvailable = false;
  scanning = false;
  scanProgress = 0;
  scanningMessage = '';
  feedback = '';
  alertMessage = '';

  docData: CieIdentity = null;

  vBirthState: string = null;
  vIssuerState: string = null;
  vBirthPlace: string = null;
  vIssuerPlace: string = null;

  issuedDate: string = this.baseDate.toISOString().split('T')[0];
  nationality: string = null;
  completedScan = false;
  manualInsert: any = true;

  minDate: string;
  maxDate: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private platform: Platform,
    private cieService: NgxCieService,
    private cieImageDecoder: NgxDecoderService,
    private cd: ChangeDetectorRef,
    private toastCtrl: ToastController,
    private keyinhost: KeyinHostService,
    private global: GlobalService,
    private modalSvc: ModalService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController
  ) {

    this.minDate = moment().format('YYYY-MM-DD');
    this.maxDate = moment().add(20,'y').format('YYYY-MM-DD');

    this.nfcAvailable = this.keyinhost.isNfcAvailable();
  }

  async ngOnInit() {

    this.guestForm = new FormGroup({
      guestType: new FormControl(4, Validators.required),
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', this.conditionalPhoneValidator()),
      lang: new FormControl('en', Validators.required),
      documentId: new FormControl(null),
      //documentExpire: new FormControl(null),
      birthDate: new FormControl(null),
      birthPlace: new FormControl(null),
      birthDistrict: new FormControl(null),
      birthState: new FormControl(null),
      gender: new FormControl(null),
      personalNumber: new FormControl(null),
      country: new FormControl(null),
      nationality: new FormControl(null),
      documentType: new FormControl(null),
      documentIssuer: new FormControl(null),
      documentIssuerPlace: new FormControl(null),
      documentIssuerDistrict: new FormControl(null),
      documentIssuerState: new FormControl(null),
      documentIssuedDate: new FormControl(null),
      documentExpireDate: new FormControl(null),
      documentKey: new FormControl(null),
      documentPhoto: new FormControl(null),
      documentPhotoMime: new FormControl(null)
    });

    if (this.nfcAvailable) {
        this.cieService.scanning.pipe(takeUntil(this.unsubscribe$)).subscribe(s => {
        this.scanning = s;
        this.cd.detectChanges();
        });

        this.cieService.feedback.pipe(takeUntil(this.unsubscribe$)).subscribe(f => {
        this.feedback = f;
        this.cd.detectChanges();
        });

        this.cieService.onscanerror = (err) => {
        console.log('RFID Error: ', err);
        this.showToast('RFID Error: ' + err, 'danger', 'bottom', 4000);
        };
    }
  }

  async presentAlert(header: string, message: string, buttons: any[] = ['OK']) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons
    });

    await alert.present();
  }

  conditionalPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phone = control.value;
      if (phone === null || phone === '') {
        return null; // Valid if empty
      }

      const error = { phone: true };
      let phoneNumber;
      try {
        phoneNumber = PhoneNumberUtil.getInstance().parse(control.value.nationalNumber, control.value.isoCode);
      }
      catch (e) {
        return error;
      }
      if (!phoneNumber) {
        return error;
      }
      if (!PhoneNumberUtil.getInstance().isPossibleNumber(phoneNumber)) {
        return error;
      }
    };
  }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }


  async onSubmit() {
    let guestData: EditGuestData = null;
    try {
      this.isSubmitted = true;

      if (this.guestForm.valid) {
        if (this.guestForm.value.phone){
          this.guestForm.value.phone = this.guestForm.value.phone.e164Number;
        }
        guestData = this.guestForm.value;
        guestData.contactId = this.reservationData.contactId;
        guestData.notifyGuest = !this.completedScan; // Notify guest if not scanned
        guestData.canEdit = this.manualInsert;

        // correction for manual entry
        if (!guestData.country) {
          guestData.country = guestData.nationality;
        }
        if (!guestData.documentKey) {
          if (guestData.documentId) {
              guestData.documentKey = Md5.hashStr(guestData.documentId);
          }
        }

        const component = this;
        this.keyinhost.newGuest(this.reservationData.id, guestData, new class extends BaseGenericCallback<Response<number>> {
            onSuccess(response: Response<number>) {
                console.log('Created new guest');
                component.modalSvc.modalDismiss();
                component.isSubmitted = false;
                component.guestForm.reset(); // Reset form if needed
                component.global.successToast('Guest created');
                component.parent.setMode('Book');
                component.parent.searchReservations(component.parent.searchBar);
            }
            onFail(exc: any) {
                console.log('Error creating guest, ' + exc);
                component.modalSvc.modalDismiss();
                component.isSubmitted = false;

                let msg = 'Could not create guest, please try again. [' + exc.status + ']';
                if (exc.status === 400) {
                    msg += '[Missing or invalid parameters]';
                }
                else if (exc.status === 409) {
                    msg += '[Guest already present]';
                }
                component.global.showAlert(msg, 'Error creating guest');
                this.page.keyinhost.logMessage({method: 'NewGuestComponent.onSubmit', input: guestData,
                    result: 'Errore creating guest: ' + msg, error: exc}, 'error');
            }
        }(this));
      }
    } catch (e) {
      console.log('Error creating guest', e);
      this.keyinhost.logMessage({method: 'NewGuestComponent.onSubmit', input: guestData,
            result: 'Errore creating guest', error: e}, 'error');
      this.global.errorToast();
    }
  }

  toggleDocumentFields() {
    this.showDocumentFields = !this.showDocumentFields;
  }

  close() {
    this.stopScan();
    this.modalSvc.modalClose();
  }



  async scanDocument(): Promise<void> {
    if (this.scanning) {
      return;
    }
    this.resetScannedData();

    const docNum = this.guestForm.value.documentId.toUpperCase();
    const ed = moment(this.guestForm.value.documentExpireDate).format('YYYY-MM-DD');
    const dob = moment(this.guestForm.value.birthDate).format('YYYY-MM-DD');

    console.log('form: docNum: ' + docNum + ', dob: ' + dob + ', ed: ' + ed);
    console.log('function: docNum: ' + docNum.trim() + ', dob: ' + this.shortDate(dob) + ', ed: ' + this.shortDate(ed));

    try {
      this.scanning = true;
      this.scanningMessage = 'Posiziona il bordo superiore del telefono sul documento e tienilo in posizione';
      this.scanProgress = 0;
      this.updateAlertMessage();

      const readingSession = new ReadingSession(this.platform, true);

      readingSession.feedback().subscribe(feedback => {
        console.log('Feedback:', feedback);
        this.feedback = feedback;
        if (feedback.includes('Found device')) {
          this.scanningMessage = 'Documento rilevato. Mantieni il telefono in posizione fino alla fine della lettura.';
        }
        this.updateAlertMessage();
      });


      readingSession.progressUpdates().subscribe(progress => {
        console.log('Progress:', progress, '/5');
        this.scanProgress = progress;
        this.updateAlertMessage();
      });

      const identity = await readingSession.scan(this.shortDate(dob), this.shortDate(ed), docNum.trim());

      if (identity) {
        const img = identity?.face_info?.images[0];
        this.photoData = Base64.encodeBase64(new Uint8Array(Util.ascii2bytes(decodeURIComponent(atob(img.media.data)))));

        if (img.media.mime_type !== ImageInfos.PNG_MIME_TYPE) {
          this.photoMime = 'image/jpg';
        }

        if (img.media.mime_type !== ImageInfos.PNG_MIME_TYPE) {
          console.log('converted foto data to png');
          this.photoString = 'data:' + img.media.mime_type + ';base64,' + img.media.data;
        } else {
          this.photoString = 'data:' + img.media.mime_type + ';base64,' + img.media.data;
        }

        this.docData = identity;
        this.vBirthState = this.docData.issuing_state;
        this.vIssuerState = this.docData.issuing_state;

        if (this.docData.issuing_state === 'ITA') {
          this.vBirthPlace = this.docData.birth_place?.join(',');
          this.vIssuerPlace = this.docData.birth_place?.join(',');
        }

        this.patchValue();

        this.scanningMessage = 'Scansione completata con successo!';
        this.showToast('RFID Read OK', 'success', 'bottom', 4000);
        this.completedScan = true;
        this.manualInsert = false;
      } else {
        this.showToast('NFC scan cancelled or failed', 'warning', 'bottom', 4000);
      }
    } catch (e) {
      console.log('RFID Error: ', e);
      this.scanningMessage = 'Scansione annullata o fallita';
      this.scanning = false;
      this.showToast('RFID Error: ' + e, 'danger', 'bottom', 4000);
      this.resetScannedData();
      this.keyinhost.logMessage({
        method: 'NewGuestComponent.scanDocument',
        input: {
          form: { docNum, dob, ed },
          function: { docNum: docNum.trim(), dob: this.shortDate(dob), ed: this.shortDate(ed) }
        },
        result: 'RFID Error',
        error: e
      }, 'error');
    } finally {
      setTimeout(() => {
        this.scanning = false;
        this.updateAlertMessage();
        this.cd.detectChanges();
      }, 2000);
    }
  }

  updateAlertMessage() {
    this.alertMessage = `
      <p>${this.scanningMessage}</p>
      <progress value="${this.scanProgress}" max="5"></progress>
    `;
    this.cd.detectChanges();
  }

  // async scanDocument(): Promise<void> {
  //   if (this.scanning) {
  //       return;
  //   }
  //   this.resetScannedData();

  //   const docNum = this.guestForm.value.documentId.toUpperCase();
  //   const ed = moment(this.guestForm.value.documentExpireDate).format('YYYY-MM-DD');
  //   const dob= moment(this.guestForm.value.birthDate).format('YYYY-MM-DD');

  //   console.log('form: docNum: ' + docNum + ', dob: ' + dob + ', ed: ' + ed);
  //   console.log('function: docNum: ' + docNum.trim() + ', dob: ' + this.shortDate(dob) + ', ed: ' + this.shortDate(ed));
  //   try {
  //     const identity = await this.cieService.scan(this.shortDate(dob), this.shortDate(ed), docNum.trim());
  //     const img = identity?.face_info?.images[0];
  //     this.photoData = Base64.encodeBase64(new Uint8Array(Util.ascii2bytes(decodeURIComponent(atob(img.media.data)))));

  //     if (img.media.mime_type !== ImageInfos.PNG_MIME_TYPE) {
  //       this.photoMime = 'image/jpg';
  //     }
  //     /*if (img.media.mime_type === ImageInfos.JPEG_MIME_TYPE) {
  //       this.photoMime = img.media.mime_type;
  //     }
  //     else if (img.media.mime_type === ImageInfos.JPEG2000_MIME_TYPE) {
  //       this.photoMime = ImageInfos.JPEG_MIME_TYPE;
  //     }*/

  //     // Convert image to PNG, not used at the time
  //     if (img.media.mime_type !== ImageInfos.PNG_MIME_TYPE) {
  //       //const newData = await this.cieImageDecoder.decode(img.media);
  //       console.log('converted foto data to png');
  //       //this.photoString = 'data:' + newData.mime_type + ';base64,' + newData.data;
  //       this.photoString = 'data:' + img.media.mime_type + ';base64,' + img.media.data;
  //       //this.photoData = newData.data;
  //       //this.photoMime = newData.mime_type;
  //     } else {
  //       this.photoString = 'data:' + img.media.mime_type + ';base64,' + img.media.data;
  //       //this.photoData = img.media.data;
  //       //this.photoMime = img.media.mime_type;
  //     }

  //     this.docData = identity;
  //     this.vBirthState = this.docData.issuing_state;
  //     this.vIssuerState = this.docData.issuing_state;

  //     if (this.docData.issuing_state === 'ITA') {
  //       this.vBirthPlace = this.docData.birth_place?.join(',');
  //       this.vIssuerPlace = this.docData.birth_place?.join(',');
  //     }

  //     this.patchValue();

  //     this.showToast('RFID Read OK', 'success', 'bottom', 4000);
  //     this.completedScan = true;
  //     this.manualInsert = false;
  //     this.cd.detectChanges();
  //   } catch (e) {
  //     console.log('RFID Error: ', e);
  //     this.showToast('RFID Error: ' + e, 'danger', 'bottom', 4000);
  //     // SOME ERRORS OCCURRED DURING SCANNING, DO SOMETHING
  //     this.resetScannedData();
  //     this.keyinhost.logMessage({method: 'NewGuestComponent.scanDocument', input: {form: {
  //           docNum, dob, ed
  //       }, function: {
  //           docNum: docNum.trim(), dob: this.shortDate(dob), ed: this.shortDate(ed)
  //       }}, result: 'RFID Error', error: e}, 'error');
  //   }
  // }

  patchValue(){
    this.guestForm.patchValue({
      name: this.docData.first_name,
      surname: this.docData.last_name,
      birthDate: this.fullDate(this.docData.mrz_birth_date),
      birthState: this.docData.issuing_state,
      birthPlace:  this.docData.birth_place?.join(','),
      //birthDistrict:  this.birthDistrict || this.docData.birth_district?.join(','),
      country: this.docData.issuing_state,
      gender: this.docData.gender,
      personalNumber: this.docData.personal_number,
      nationality: this.nationality || this.docData.nationality,
      documentId: this.docData.document_number,
      documentType: this.docData.mrz_type,
      documentIssuer:this.docData.issuing_authority,
      //documentIssuerPlace: this.docData.issuing_place,
      //documentIssuerDistrict = this.issuerPlace || this.docData.issuing_state;
      documentIssuerState: this.docData.issuing_state,
      documentIssuedDate: this.fullDateIssue(this.docData.date_of_issue) || this.issuedDate?.split('T')[0],
      documentExpireDate: this.fullDate(this.docData.mrz_expire_date),
      documentKey: Md5.hashStr(this.docData.document_number),
      documentPhoto: this.photoData,
      documentPhotoMime: this.photoMime
    });
  }

  async stopScan(): Promise<void> {
    if (!this.scanning) {
        return;
    }
    await this.cieService.stopScan();
    this.cd.detectChanges();
    this.scanning = false;
    this.updateAlertMessage();
  }

  onAlertDismiss(event: any) {
    if (event.detail.role === 'cancel') {
      this.stopScan();
    }
  }


  resetScannedData() {
    this.photoData = '';
    this.photoMime = '';
    this.docData = null;
    //this.width = 0;
    //this.height = 0;
    this.feedback = '';
  }

  public shortDate(date: string): string {
    return format(parse(date, 'yyyy-MM-dd', this.baseDate), 'yyMMdd');
  }

  public fullDate(date: string): string {
    if (!date) { return undefined; }
    const dd = parse(date, 'yyMMdd', this.baseDate);
    return formatISO(dd, {format: 'extended', representation: 'date'});
  }

  public fullDateIssue(date: string): string {
    if (!date) { return undefined; }
    const dd = parse(date, 'yyyyMMdd', new Date());
    return formatISO(dd, {format: 'extended', representation: 'date'});
  }

  async showToast(msg, color, position, duration = 3000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration,
      color,
      position
    });
    toast.present();
  }


  async openCountrySearch(field: string) {
    const modal = await this.modalCtrl.create({
      component: CountrySearchModalComponent
    });

    modal.onDidDismiss().then(data => {
      if (data.data) { // Ensure data is not empty
        this.guestForm.controls[field].setValue(data.data);
      }
    });

    await modal.present();
  }

  async openCitySearch(field: string) {
    const modal = await this.modalCtrl.create({
      component: CitySearchModalComponent
    });

    modal.onDidDismiss().then(data => {
      if (data.data) { // Ensure data is not empty
        this.guestForm.controls[field].setValue(data.data);
      }
    });

    await modal.present();
  }

}
