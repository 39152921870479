import { Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { KeyinHostService } from 'app/services/keyin/keyin-host.service';
import { EmailComposerOptions } from '@awesome-cordova-plugins/email-composer';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public version: string = environment.appVersion;
  public production: boolean = environment.production;
  public privacyPolicy: string = environment.privacyPolicy;
  public eula: string = environment.eula;

  constructor(private platform: Platform, private router: Router, private iab: InAppBrowser, private zone: NgZone,
              private emailComposer: EmailComposer, private keyinhost: KeyinHostService, private translate: TranslateService) {
                translate.setDefaultLang('en');
                const browserLang = translate.getBrowserLang();
                translate.use(browserLang.match(/en|es|fr/) ? browserLang : 'en');

                this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.platform.backButton.subscribe(() => {
        const url = this.router.url;

        /*if (url === '/tabs/not-home') {
            this.router.navigate(['/tabs/home']);
        } else*/
        if ((url === '/main' ) || (url === '/login')) {
            App.exitApp();
            //alert('Exiting');
        }
      });

      this.platform.resume.subscribe(() => {
        const url = this.router.url;
        console.log('Resume: ' + url);

        this.zone.run(() => {
            this.keyinhost.checkToken();
        });
      });
    });
  }

  currentYear(): number {
      return new Date().getFullYear();
  }

  showPP() {
      const browser = this.iab.create(this.privacyPolicy);
  }

  showEULA() {
      const browser = this.iab.create(this.eula);
  }

  contactUs() {
    if (this.platform.is('desktop')) {
        // If on web, use mailto link
        window.location.href = `mailto:support@jthanvision.com?subject=${encodeURIComponent(this.keyinhost.getSourceId() + ' - Please define contact reason')}&body=Please, detail contact reason`;
    } else {
        // Existing email composer code for mobile devices
        const email: EmailComposerOptions = {
            to: 'support@jthanvision.com',
            subject: this.keyinhost.getSourceId() + ' - Please define contact reason',
            body: 'Please, detail contact reason',
            isHtml: true
        };
        this.emailComposer.open(email);
    }
  }

}
