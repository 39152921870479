export interface GenericCallback<T> {
    onSuccess(response: T);
    onFail(exc: any);
}

export abstract class BaseGenericCallback<T> implements GenericCallback<T> {
    constructor(protected page: any) {}

    public abstract onSuccess(response: T);
    public abstract onFail(exc: any);
}
