import { Injectable } from '@angular/core';
import { TimeSignService } from 'app/services/utils/timesign.service';
import { HostData, Response, Paging, Role, RoleChecker } from 'app/api/model';
import { DefaultService as HostLoginApi,
    ChangePassword, ResetPassword,
    Login, ChangePin,
    JWTToken,
    RefreshJWTToken} from 'app/api/hostlogin';
import { DefaultService as HostAdminApi,
    HostPropertyData, RoomData, RoomEnablementData,
    GuestData, ReservationOCData, RoomAssociationData,
    BookTimeOffset, NewReservationData, ServiceAssociationData,
    ReservationData, PropertyServiceData, NotifyReservationData,
    EditGuestData} from 'app/api/hostadmin';
import { DefaultService as OnlineSelfApi,
    ActionRequest, ActionLog} from 'app/api/actions';
import {
    DefaultService as LoggingApi,
    LogData
} from 'app/api/logging';

import {
    DefaultService as PortaleAlloggiatiApi,
    PortaleAlloggiatiRunData
} from 'app/api/portalealloggiati';

import { GenericCallback } from 'app/utils/generic.interface';

import { sprintf } from 'sprintf-js';
import { Config } from 'app/utils/config';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { StorageService } from '../storage/storage.service';
import { DeviceInfo } from '@capacitor/device';
import { environment } from 'environments/environment';
import { CityData } from 'app/api/hostadmin/model/cityData';
import { CountryData } from 'app/api/hostadmin/model/countryData';
import { GlobalService } from '../global/global.service';

@Injectable({
    providedIn: 'root'
})
export class KeyinHostService {

    private hostId = -1;
    private propertyId = -1;
    private inUsePin = -1;
    private inUsePinDuration = 0; // PIN should be used every time
    private inUsePinTimer = null;
    private usingBiometric = false;
    private pin = -1;
    private email: string;
    private sourceId: string;
    private deviceInfo: DeviceInfo = null;
    private nfcSupported = false;
    private nfcEnabled = false;
    private actionKey: string;
    private token: JWTToken = null;
    private refreshTokenTimer = null;

    private hostData: HostData;
    private property: HostPropertyData;
    private roleChecker = new RoleChecker('GUEST');

    constructor(private hostLoginAPI: HostLoginApi,
        private hostAdminAPI: HostAdminApi,
        private olSelfAPI: OnlineSelfApi,
        private logAPI: LoggingApi,
        private paAPI: PortaleAlloggiatiApi,
        private encoder: TimeSignService,
        private router: Router,
        private storage: StorageService,
        private global: GlobalService) { }

    public getEmail() {
        return this.email;
    }

    public setEmail(email: string) {
        this.email = email;
    }

    public getToken() {
        return this.token;
    }

    public setToken(token: JWTToken) {
        this.token = token;
        this.storage.set('JWT', token).then();
    }

    public getSourceId() {
        return this.sourceId;
    }

    public setSourceId(sourceId: string) {
        this.sourceId = sourceId;
    }

    public getDeviceInfo() {
        return this.deviceInfo;
    }

    public setDeviceInfo(deviceInfo: DeviceInfo) {
        this.deviceInfo = deviceInfo;
    }

    public getHostId() {
        return this.hostId;
    }

    public setHostId(hostId: number) {
        this.hostId = hostId;
        return this;
    }

    public getPropertyId() {
        return this.propertyId;
    }

    public setPropertyId(propertyId: number) {
        this.propertyId = propertyId;
        return this;
    }

    public getPin() {
        return this.pin;
    }

    public setPin(pin: number) {
        this.pin = pin;
    }

    public getInUsePin() {
        return this.inUsePin;
    }

    public setInUsePin(inUsePin: number) {
        this.inUsePin = -1;
        clearTimeout(this.inUsePinTimer);
        const timeout = Config.getInt('host.selfin.inUsePinDuration' , this.inUsePinDuration);
        if ((timeout > 0) && (inUsePin !== -1)) {
            this.inUsePin = inUsePin;
            console.log('Setting PIN duration to ' + timeout + ' seconds');
            this.inUsePinTimer = setTimeout(() => {
                console.log('Resetting PIN');
                this.setInUsePin(-1);
            }, timeout * 1000);
        }
        return this;
    }

    public isUsingBiometric() {
        return this.usingBiometric;
    }

    public setUsingBiometric(usingBiometric: boolean) {
        this.usingBiometric = usingBiometric;
    }

    public isNfcSupported() {
        return this.nfcSupported;
    }

    public setNfcSupported(supported: boolean) {
        this.nfcSupported = supported;
    }

    public isNfcEnabled() {
        return this.nfcEnabled;
    }

    public setNfcEnabled(enabled: boolean) {
        this.nfcEnabled = enabled;
    }

    public isNfcAvailable() {
        return this.nfcSupported && this.nfcEnabled;
    }

    public checkToken(): boolean {
        const token = this.getToken();
        console.log('Checking token: ' + JSON.stringify(token));
        if(token != null) {
            if (moment.utc(token.refreshExpiresAt).isBefore(moment())) {
                console.log('checkToken: refresh token expired');
                this.logMessage({method: 'KeyinHostService.checkToken', input: token,
                                result: 'Refresh token expired for [' + this.getHostId() + ']'}, 'error');
                this.global.errorToast('Session expired, please login again');
                this.setToken(null);
                // redirect to login page
                this.router.navigateByUrl('/login');
                return false;
            }
            if (moment.utc(token.accessExpiresAt).isBefore(moment())) {
                console.log('checkToken: access token expired');
                this.global.errorToast('Token expired, trying refresh');
                this.logMessage({method: 'KeyinHostService.checkToken', input: token,
                                result: 'Access token expired for [' + this.getHostId() + ']'}, 'error');
                this.startTokenRefreshTimer(true);
            }
            console.log('checkToken: token valid');
            return true;
        }
        else {
            console.log('checkToken: token not found');
            this.global.errorToast('Session expired, please login again');
            this.logMessage({method: 'KeyinHostService.checkToken', input: null,
                result: 'Token not found for [' + this.getHostId() + '] go to login'}, 'error');
            // redirect to login page
            this.router.navigateByUrl('/login');
            return false;
        }
    }

    public startTokenRefreshTimer(immediate: boolean = false) {
        if (!this.token) {
            if (this.refreshTokenTimer) {
                clearInterval(this.refreshTokenTimer);
            }
            return;
        }
        let timeout = 0.5;
        if (!immediate) {
            timeout = moment.utc(this.token.accessExpiresAt).diff(moment(), 'seconds') - 30;
        }
        console.log('Token refresh in ' + timeout + ' seconds');
        if (this.refreshTokenTimer) {
            clearInterval(this.refreshTokenTimer);
        }
        this.refreshTokenTimer = setInterval(() => {
            this.refreshToken(null);
        }, timeout * 1000);
    }

    public login(email: string, password: string, callback: GenericCallback<Response<HostData>>) {
        try {
            this.setEmail(email);
            const login: Login = {};
            login.email = email;
            login.password = password;

            this.hostLoginAPI.login(login).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Login OK');
                        // TEST!!!
                        //result.data.refreshExpiresAt = moment().add(30, 'second').toDate();

                        this.setToken(result.data);
                        this.startTokenRefreshTimer();

                        this.getHostData(callback);
                    }
                    else {
                        console.log('Error executing login[' + email + ']:' + JSON.stringify(result));
                        this.setToken(null);
                        this.logMessage({method: 'KeyinHostService.login', input: this.getToken(),
                                        result: 'Error executing login for [' + email + ']', error: result}, 'error');
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error executing login[' + email + ']:' + JSON.stringify(error));
                    this.setToken(null);
                    this.logMessage({method: 'KeyinHostService.login', input: this.getToken(),
                                    result: 'Error executing login for [' + email + ']', error}, 'error');
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error logging in [' + email + ']: ' + JSON.stringify(exc));
            this.logMessage({method: 'KeyinHostService.login', input: this.getToken(),
                            result: 'Error logging in [' + email + ']', error: exc}, 'error');
        }
        return this;
    }

    public isRoleAllowed(role: Role) {
        return this.roleChecker.isRoleAllowed(role);
    }

    public getCachedHostData() {
        if (this.hostData) {
            this.roleChecker = new RoleChecker(this.hostData.role);
        }
        return this.hostData;
    }

    public resetCachedHostData() {
        this.hostData = null;
        this.roleChecker = new RoleChecker('GUEST');
    }

    public getHostData(callback: GenericCallback<Response<HostData>>) {
        try {
            this.hostLoginAPI.getHost(this.getToken().accessToken, this.getEmail()).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Host data:', result.data);
                        this.hostData = result.data;
                        this.setHostId(result.data.id);
                        this.actionKey = result.data.actionKey || '';
                        this.roleChecker = new RoleChecker(this.hostData.role);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Failed getting host[' + this.getEmail() + '] data:' + JSON.stringify(result));
                        this.resetCachedHostData();
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error getting host[' + this.getEmail() + '] data(2):' + JSON.stringify(error));
                    this.resetCachedHostData();
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error getting host [' + this.getEmail() + '] data(3): ' + JSON.stringify(exc));
            this.resetCachedHostData();
            callback.onFail(exc);
        }
        return this;
    }

    public logout(callback: GenericCallback<Response<null>>) {
        try {
            this.hostLoginAPI.logout(this.getToken().accessToken).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Logout OK');
                        this.setToken(null);
                        callback.onSuccess(result);
                        this.actionKey = null;
                    }
                    else {
                        console.log('Failed executing logout:' + JSON.stringify(result));
                        this.setToken(null);
                        this.logMessage({method: 'KeyinHostService.logout', input: this.getToken(),
                                        result: 'Failed executing logout for [' + this.getHostId() + ']', error: result}, 'error');
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error executing logout:' + JSON.stringify(error));
                    this.setToken(null);
                    this.logMessage({method: 'KeyinHostService.logout', input: this.getToken(),
                                    result: 'Error executing logout for [' + this.getHostId() + ']', error}, 'error');
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error logging out: ' + JSON.stringify(exc));
            this.logMessage({method: 'KeyinHostService.logout', input: this.getToken(),
                            result: 'Error executing logout for [' + this.getHostId() + ']', error: exc}, 'error');
        }
        return this;
    }

    public async refreshToken(callback, login = true) {
        const refrToken: RefreshJWTToken = {};
        try {
            refrToken.refreshToken = this.getToken().refreshToken;

            this.hostLoginAPI.refreshToken(refrToken).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Token refreshed');
                        this.setToken(result.data);
                        this.startTokenRefreshTimer();
                        if (callback) {
                            callback.onSuccess(result);
                        }
                    }
                    else {
                        console.log('Failed refreshing token:' + JSON.stringify(result));
                        this.logMessage({method: 'KeyinHostService.refreshToken', input: refrToken,
                                        result: 'Failed refreshing token for [' + this.getHostId() + ']', error: result}, 'error');
                        this.setToken(null);
                        if (login) {
                            this.global.errorToast('Session expired, please login again');
                            this.router.navigateByUrl('/login');
                        }
                        else {
                            if (callback) {
                                callback.onFail(result);
                            }
                        }
                    }
                },
                error: error => {
                    console.log('Error refreshing token:' + JSON.stringify(error));
                    this.logMessage({method: 'KeyinHostService.refreshToken', input: refrToken,
                                    result: 'Error refreshing token for [' + this.getHostId() + ']', error}, 'error');

                    this.setToken(null);
                    if (login) {
                        this.global.errorToast('Session expired, please login again');
                        this.router.navigateByUrl('/login');
                    }
                    else {
                        if (callback) {
                            callback.onFail(error);
                        }
                    }
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error refreshing token for [' + this.getHostId() + ']: ' + JSON.stringify(exc));
            this.logMessage({method: 'KeyinHostService.refreshToken', input: refrToken,
                            result: 'Error refreshing token for [' + this.getHostId() + ']', error: exc}, 'error');
            this.setToken(null);
            if (login) {
                this.global.errorToast('Session expired, please login again');
                this.router.navigateByUrl('/login');
            }
            else {
                if (callback) {
                    callback.onFail(exc);
                }
            }
        }
        return this;
    }

    public changePassword(oldPassword: string, newPassword: string, callback: GenericCallback<Response<null>>) {
        try {
            const changePassword: ChangePassword = {};
            changePassword.oldPassword = oldPassword;
            changePassword.newPassword = newPassword;

            this.hostLoginAPI.changePassword(this.getToken().accessToken, this.getHostId(), changePassword).subscribe({
                next: result => {
                    if (result.success) {
                        callback.onSuccess(result);
                        this.actionKey = null;
                    }
                    else {
                        console.log('Error setting password for [' + this.getEmail() + ']:' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error setting password for [' + this.getEmail() + ']:' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error setting password for [' + this.getEmail() + ']:' + JSON.stringify(exc));
        }
        return this;
    }

    public changePin(oldPin: number, newPin: number, callback: GenericCallback<Response<null>>) {
        try {
            this.setInUsePin(-1);
            const changePin: ChangePin = {};
            changePin.oldPin = oldPin;
            changePin.newPin = newPin;

            console.log('ChangePin: ' + JSON.stringify(changePin));

            this.hostLoginAPI.changePin(this.getToken().accessToken, this.getHostId(), changePin).subscribe({
                next: result => {
                    if (result.success) {
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error setting PIN for [' + this.getEmail() + ']:' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error setting PIN for [' + this.getEmail() + ']:' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error setting PIN for [' + this.getEmail() + ']:' + JSON.stringify(exc));
        }
        return this;
    }


    public resetPassword(email: string, callback: GenericCallback<Response<null>>) {
        try {
            const resetPassword: ResetPassword = {};
            resetPassword.email = email;

            this.hostLoginAPI.resetPassword(resetPassword).subscribe({
                next: result => {
                    if (result.success) {
                        this.setEmail(email);
                        callback.onSuccess(result);
                        this.actionKey = null;
                    }
                    else {
                        console.log('Error resetting password for [' + email + ']:' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error resetting password for [' + email + ']:' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error resetting password for [' + email + ']:' + JSON.stringify(exc));
        }
        return this;
    }

    public getCachedHostPropertyData() {
        return this.property;
    }

    public resetCachedHostPropertyData() {
        this.property = null;
        this.roleChecker = new RoleChecker('GUEST');
    }

    public getHostPropertyData(callback: GenericCallback<Response<HostPropertyData>>) {
        try {
            this.hostAdminAPI.getHostPropertyData(this.getToken().accessToken, this.getPropertyId()).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Property: ', result);
                        this.property = result.data;
                        this.roleChecker = new RoleChecker(this.property.hostData.properties[this.getPropertyId()].role);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error getting property [' + this.getPropertyId() + '] data for hostId[' + this.getHostId() + ']: ' + JSON.stringify(result));
                        this.resetCachedHostPropertyData();
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error getting property [' + this.getPropertyId() + '] data for hostId[' + this.getHostId() + ']: ' + JSON.stringify(error));
                    this.resetCachedHostPropertyData();
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error getting property [' + this.getPropertyId() + '] data for hostId[' + this.getHostId() + ']: ' + JSON.stringify(exc));
            this.resetCachedHostPropertyData();
        }
        return this;
    }

    public getPropertyReservationData(filter: string, manual: boolean, temporal: string, pageNum: number, pageSize: number,
        callback: GenericCallback<Response<Paging<ReservationData>>>) {
        try {
            this.hostAdminAPI.getPropertyReservationData(this.getToken().accessToken, this.getPropertyId(),
                                                        pageNum, pageSize, filter, manual, temporal).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Reservations: ' + result);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error getting reservations for hostId[' + this.getHostId() + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error getting reservations for hostId[' + this.getHostId() + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error getting reservations for hostId[' + this.getHostId() + ']: ' + JSON.stringify(exc));
        }
        return this;
    }


    public getFreeRoomPropertyData(reservationId: string, callback: GenericCallback<Response<Array<RoomData>>>) {
        try {
            this.hostAdminAPI.getFreeRoomPropertyData(this.getToken().accessToken, this.getPropertyId(), reservationId).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Rooms: ' + result);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error getting free rooms for hostId[' + this.getHostId() + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error getting free rooms for hostId[' + this.getHostId() + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error getting free rooms for hostId[' + this.getHostId() + ']: ' + JSON.stringify(exc));
        }
        return this;
    }


    public updateReservationOCStatus(guest: GuestData, ocStatus: number, ocNotes: string, callback: GenericCallback<Response<null>>) {
        try {
            const ocData: ReservationOCData = {};
            ocData.status = ocStatus;
            ocData.notes = ocNotes;

            this.hostAdminAPI.updateReservationOCStatus(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), guest.reservationId, guest.id, ocData).subscribe({
                next: result => {
                    if (result.success) {
                        guest.ocStatus = ocStatus;
                        guest.ocNotes = ocNotes;
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error updating OcStatus for GuestID[' + guest.id + '] and ReservationID[' + guest.reservationId + ']:' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error updating OcStatus for GuestID[' + guest.id + '] and ReservationID[' + guest.reservationId + ']:' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error updating OcStatus for GuestID[' + guest.id + '] and ReservationID[' + guest.reservationId + ']:' + JSON.stringify(exc));
        }
        return this;
    }

    public manageRoomStatus(roomId: number, enabled: boolean, callback: GenericCallback<Response<null>>) {
        try {
            const enablement: RoomEnablementData = {};
            enablement.enabled = enabled;
            enablement.force = true;

            this.hostAdminAPI.manageRoomStatus(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), roomId, enablement).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Manage room OK');
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error executing manage room:' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error executing manage room:' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error managing room: ' + JSON.stringify(exc));
        }
        return this;
    }

    public associateRoomToGuest(reservationId: string, associationData: RoomAssociationData, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.associateRoomToGuest(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, associationData).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Associated Room/Guest for ReservationID[' + reservationId + ']');
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error associating Room/Guest for ReservationID[' + reservationId + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error associating Room/Guest for ReservationID[' + reservationId + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error associating Room/Guest for ReservationID[' + reservationId + ']: ' + JSON.stringify(exc));
        }
        return this;
    }

    public associateServiceToReservation(reservationData: ReservationData, propertyService: PropertyServiceData, itemId: number, params: any, callback: GenericCallback<Response<null>>) {
        try {
            const associationData: ServiceAssociationData = {};
            associationData.type = propertyService.type;
            associationData.itemId = itemId;
            associationData.params = params;
            associationData.force = true;

            console.log('associationData: ' + JSON.stringify(associationData));

            this.hostAdminAPI.associateServiceToReservation(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationData.id, associationData).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Associated Service[' + propertyService.id + '/' + propertyService.type + '/' + itemId + '] to ReservationID[' + reservationData.id + ']');
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error associating Service[' + propertyService.id + '/' + propertyService.type + '/' + itemId + '] to ReservationID[' + reservationData.id + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error associating Service[' + propertyService.id + '/' + propertyService.type + '/' + itemId + '] to ReservationID[' + reservationData.id + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error associating Service[' + propertyService.id + '/' + propertyService.type + '/' + itemId + '] to ReservationID[' + reservationData.id + ']: ' + JSON.stringify(exc));
        }
        return this;
    }

    public updateReservationDateTime(reservationId: string, checkIn: string, checkOut: string, checkInBefore: string, checkOutAfter: string, callback: GenericCallback<Response<null>>) {
        try {
            const bookTimeOffset: BookTimeOffset = {};
            bookTimeOffset.checkIn = checkIn;
            bookTimeOffset.checkOut = checkOut;
            bookTimeOffset.checkInBefore = checkInBefore;
            bookTimeOffset.checkOutAfter = checkOutAfter;

            this.hostAdminAPI.updateReservationDateTime(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, bookTimeOffset).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Updated ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']');
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error updating ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error updating ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error updating ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(exc));
        }
        return this;
    }

    public resetReservationDateTime(reservationId: string, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.resetReservationDateTime(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Resetted ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']');
                        this.refreshHostPropertyData();
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error resetting ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error resetting ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error resetting ReservationID[' + reservationId + '] date/time from HostID[' + this.getHostId() + ']: ' + JSON.stringify(exc));
        }
        return this;
    }

    public newReservation(reservation: NewReservationData, callback: GenericCallback<Response<string>>) {
        try {
            this.hostAdminAPI.newReservation(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservation).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Adding new reservation: ' + JSON.stringify(reservation));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error adding new reservation: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error adding new reservation: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error adding new reservation: ' + JSON.stringify(exc));
        }
        return this;
    }

    public updateReservation(reservationId: string, reservation: NewReservationData, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.updateReservation(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, reservation).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Changing reservation: ' + JSON.stringify(reservation));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error changing reservation: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error changing reservation: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error changing reservation: ' + JSON.stringify(exc));
        }
        return this;
    }

    public deleteReservation(reservationId: string, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.deleteReservation(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Deleted reservation: ' + reservationId);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error deleting reservation: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error deleting reservation: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error deleting reservation: ' + JSON.stringify(exc));
        }
        return this;
    }

    public getGuest(reservationId: string, guestId: number, callback: GenericCallback<Response<EditGuestData>>) {
        try {
            this.hostAdminAPI.getGuest(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, guestId).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Found guest: ' + JSON.stringify(result.data));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error searching guest: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error searching guest: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error searching guest: ' + JSON.stringify(exc));
        }
        return this;
    }

    public newGuest(reservationId: string, guestData: EditGuestData, callback: GenericCallback<Response<number>>) {
        try {
            this.hostAdminAPI.newGuest(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, guestData).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Added new guest: ' + JSON.stringify(guestData));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error adding new guest: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error adding new guest: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error adding new guest: ' + JSON.stringify(exc));
        }
        return this;
    }

    public updateGuest(reservationId: string, guestId: number, guestData: EditGuestData, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.updateGuest(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, guestId, guestData).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Changed guest: ' + JSON.stringify(guestData));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error changing guest: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error changing guest: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error changing guest: ' + JSON.stringify(exc));
        }
        return this;
    }

    public deleteGuest(reservationId: string, guestId: number, callback: GenericCallback<Response<null>>) {
        try {
            this.hostAdminAPI.deleteGuest(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, guestId).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Deleted guest: ', reservationId, guestId);
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error deleting guest: ',  + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error deleting guest: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error deleting guest: ' + JSON.stringify(exc));
        }
        return this;
    }

    public notifyReservation(reservationId: string, notifyReservationData: NotifyReservationData, callback: GenericCallback<Response<null>>) {
        try {
            notifyReservationData.hostId = this.getHostId();
            this.hostAdminAPI.notifyReservation(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId, notifyReservationData).subscribe({
                next: result => {
                    if (result.success) {
                        console.log('Notified reservation: ' + JSON.stringify(notifyReservationData));
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error notifying reservation: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error notifying reservation: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error notifying reservation: ' + JSON.stringify(exc));
        }
        return this;
    }

    public executeAction(pin: number, latitude: number, longitude: number, deviceId: number, actionId: number, callback: GenericCallback<Response<null>>) {
        this.setInUsePin(pin);
        const actionRequest: ActionRequest = {};
        actionRequest.hostId = this.getHostId();
        actionRequest.deviceId = deviceId;
        actionRequest.action = actionId;
        try {
            actionRequest.signature = this.encoder.sign(sprintf('%1$s#%2$s#%3$f#%4$f#%5$d#%6$d#%7$d#%8$d', this.getSourceId(),
                    this.actionKey, latitude , longitude, actionRequest.hostId, actionRequest.deviceId, actionRequest.action, pin), 300);

            this.olSelfAPI.executeAction(this.getToken().accessToken, actionRequest).subscribe({
                next: result => {
                    if (result.success) {
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error executing action[' + JSON.stringify(actionRequest) + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error executing action[' + JSON.stringify(actionRequest) + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });

        } catch (exc) {
            console.log('Error executing action[' + JSON.stringify(actionRequest) + ']: ' + JSON.stringify(exc));
        }
        return this;
    }

    public getResourceActionLogs(resourceId: number, callback: GenericCallback<Response<ActionLog>>) {
        try {
            this.olSelfAPI.getResourceActionLogs(resourceId).subscribe({
                next: result => {
                    if (result.success) {
                        callback.onSuccess(result);
                    }
                    else {
                        console.log('Error getting resource action logs[' + resourceId + ']: ' + JSON.stringify(result));
                        callback.onFail(result);
                    }
                },
                error: error => {
                    console.log('Error getting resource action logs[' + resourceId + ']: ' + JSON.stringify(error));
                    callback.onFail(error);
                },
                complete: () => {}
            });

        } catch (exc) {
            console.log('Error getting resource action logs[' + resourceId + ']: ' + JSON.stringify(exc));
        }
        return this;
    }


    public listaInvii(referenceDate: string, callback: GenericCallback<Response<Array<PortaleAlloggiatiRunData>>>) {
        try {
            this.paAPI.listaInvii(this.getToken().accessToken, this.getPropertyId(), referenceDate).subscribe(result => {
                if (result.success) {
                    console.log('Found lista invii: ' + JSON.stringify(result.data));
                    callback.onSuccess(result);
                }
                else {
                    console.log('Error searching lista invii: ' + JSON.stringify(result));
                    callback.onFail(result);
                }
            },
            error => {
                console.log('Error searching lista invii: ' + JSON.stringify(error));
                callback.onFail(error);
            },
            () => {}
            );
        } catch (exc) {
            console.log('Error searching lista invii: ' + JSON.stringify(exc));
        }
        return this;
    }


    public preparaInvio(referenceDate: string, callback: GenericCallback<Response<PortaleAlloggiatiRunData>>) {
        try {
            this.paAPI.preparaInvio(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), referenceDate).subscribe(result => {
                if (result.success) {
                    console.log('Creatend invii: ' + JSON.stringify(result.data));
                    callback.onSuccess(result);
                }
                else {
                    console.log('Error creating invii: ' + JSON.stringify(result));
                    callback.onFail(result);
                }
            },
            error => {
                console.log('Error creating invii: ' + JSON.stringify(error));
                callback.onFail(error);
            },
            () => {}
            );
        } catch (exc) {
            console.log('Error creating invii: ' + JSON.stringify(exc));
        }
        return this;
    }


    public preparaSingoloInvio(reservationId: string, callback: GenericCallback<Response<PortaleAlloggiatiRunData>>) {
        try {
            this.paAPI.preparaSingoloInvio(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), reservationId).subscribe(result => {
                if (result.success) {
                    console.log('Created singolo invio: ' + JSON.stringify(result.data));
                    callback.onSuccess(result);
                }
                else {
                    console.log('Error creating singolo invio: ' + JSON.stringify(result));
                    callback.onFail(result);
                }
            },
            error => {
                console.log('Error creating singolo invio: ' + JSON.stringify(error));
                callback.onFail(error);
            },
            () => {}
            );
        } catch (exc) {
            console.log('Error creating singolo invio: ' + JSON.stringify(exc));
        }
        return this;
    }


    public inviaWS(runNumber: number, reservationId: string, callback: GenericCallback<Response<null>>) {
        try {
            this.paAPI.inviaWS(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), runNumber, reservationId).subscribe(result => {
                if (result.success) {
                    console.log('Sent to PA WS: ' + JSON.stringify(result.data));
                    callback.onSuccess(result);
                }
                else {
                    console.log('Error sending to PA WS: ' + JSON.stringify(result));
                    callback.onFail(result);
                }
            },
            error => {
                console.log('Error sending to PA WS: ' + JSON.stringify(error));
                callback.onFail(error);
            },
            () => {}
            );
        } catch (exc) {
            console.log('Error sending to PA WS: ' + JSON.stringify(exc));
        }
        return this;
    }


    public inviaEmail(runNumber: number, callback: GenericCallback<Response<null>>) {
        try {
            this.paAPI.inviaEmail(this.getToken().accessToken, this.getHostId(), this.getPropertyId(), runNumber).subscribe(result => {
                if (result.success) {
                    console.log('Sent to Host email: ' + JSON.stringify(result.data));
                    callback.onSuccess(result);
                }
                else {
                    console.log('Error sending to Host email: ' + JSON.stringify(result));
                    callback.onFail(result);
                }
            },
            error => {
                console.log('Error sending to Host email: ' + JSON.stringify(error));
                callback.onFail(error);
            },
            () => {}
            );
        } catch (exc) {
            console.log('Error sending to Host email: ' + JSON.stringify(exc));
        }
        return this;
    }


    public logMessage(message: any, severity: string) {
        try {
            const logData: LogData = {};
            logData.severity = severity;
            logData.appName = environment.appName;
            logData.appVersion = environment.appVersion;
            logData.sourceId = this.getSourceId();
            logData.hostId = this.getHostId();
            logData.deviceInfo = this.getDeviceInfo();
            logData.message = message;

            this.logAPI.logMessage(logData).subscribe({
                next: result => {
                    if (!result.success) {
                        console.log('Error logging: ' + JSON.stringify(logData));
                    }
                },
                error: error => {
                    console.log('Error logging: ' + JSON.stringify(logData) + ' - ' + JSON.stringify(error));
                },
                complete: () => {}
            });
        } catch (exc) {
            console.log('Error processing log: ' + JSON.stringify(message) + ' - ' + JSON.stringify(exc));
        }
        return this;
    }

    public getCountries(filter: string, callback: GenericCallback<Response<Array<CountryData>>>=null) {
        try {
            this.hostAdminAPI.getCountries(filter).subscribe({
                next: result => {
                    console.log('Countries: ', result);
                    if (result.success) {
                        if (callback){
                            callback.onSuccess(result);
                        }
                    }
                    else {
                        console.log('Failed call to get countries for [' + filter + ']: ' + JSON.stringify(result));
                        if (callback){
                            callback.onFail(result);
                        }
                        this.logMessage({method: 'getCountries', input: filter, result}, 'error');
                    }
                },
                error: error => {
                    console.log('Failed call to get countries for [' + filter + ']: ' + JSON.stringify(error));
                    if (callback){
                        callback.onFail(error);
                    }
                    this.logMessage({method: 'getCountries', input: filter, result: error}, 'error');
                },
                complete: () => { }
            });
        } catch (exc) {
            console.log('Error getting countries for [' + filter + ']: ' + JSON.stringify(exc));
            this.logMessage({method: 'getCountries', input: filter, result: exc}, 'error');
        }
        return this;
    }

    public getCities(filter: string, callback: GenericCallback<Response<Array<CityData>>>=null) {
        try {
            this.hostAdminAPI.getCities(filter).subscribe({
                next: result => {
                    console.log('Cities: ', result);
                    if (result.success) {
                        if (callback){
                            callback.onSuccess(result);
                        }
                    }
                    else {
                        console.log('Failed call to get cities for [' + filter + ']: ' + JSON.stringify(result));
                        if (callback){
                            callback.onFail(result);
                        }
                        this.logMessage({method: 'getCities', input: filter, result}, 'error');
                    }
                },
                error: error => {
                    console.log('Failed call to get cities for [' + filter + ']: ' + JSON.stringify(error));
                    if (callback){
                        callback.onFail(error);
                    }
                    this.logMessage({method: 'getCities', input: filter, result: error}, 'error');
                },
                complete: () => { }
            });
        } catch (exc) {
            console.log('Error getting cities for [' + filter + ']: ' + JSON.stringify(exc));
            this.logMessage({method: 'getCities', input: filter, result: exc}, 'error');
        }
        return this;
    }

    private refreshHostData() {
        this.hostLoginAPI.getHost(this.getToken().accessToken, this.getEmail()).subscribe({
            next: result => {
                if (result.success) {
                    console.log('Host data:', result.data);
                    this.hostData = result.data;
                    this.setHostId(result.data.id);
                    this.actionKey = result.data.actionKey || '';
                }
                else {
                    console.log('Error getting host[' + this.getEmail() + '] data: ' + JSON.stringify(result));
                }
            },
            error: error => {
                console.log('Error getting host[' + this.getEmail() + '] data: ' + JSON.stringify(error));
            },
            complete: () => {}
        });
    }

    private refreshHostPropertyData() {
        this.hostAdminAPI.getHostPropertyData(this.getToken().accessToken, this.getPropertyId()).subscribe({
            next: result => {
                if (result.success) {
                    console.log('Property: ', result);
                    this.property = result.data;
                }
                else {
                    console.log('Error getting properties for hostId[' + this.getHostId() + ']: ' + JSON.stringify(result));
                }
            },
            error: error => {
                console.log('Error getting properties for hostId[' + this.getHostId() + ']: ' + JSON.stringify(error));
            },
            complete: () => {}
        });
    }

}
