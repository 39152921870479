import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KeyinHostService } from '../keyin/keyin-host.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalCtrl: ModalController,
    private keyinhost: KeyinHostService
  ) { }

  public async createModal(options) {
    if (this.keyinhost.checkToken()) {
        const modal = await this.modalCtrl.create(options);
        await modal.present();
        const { data } = await modal.onWillDismiss();
        console.log('createModal', data);
        if(data) {
            return data;
        }
    }
  }

  public modalDismiss(val?) {
    const data = val ? val : null;
    console.log('modalDismiss', data);
    this.modalCtrl.dismiss(data);
  }

  public modalClose() {
    this.modalCtrl.dismiss({
        dismissed: true
    });
  }

}
