import { Injectable } from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class EncodingService {

    constructor() { }

    public encode(data: string) {
        let enc = null;
        try {
            const hashtext = Md5.hashStr(data);
            const hex = this.toHexString(this.strToBytes(data)).toLowerCase();

            enc = hashtext.substring(0, 10) + hex + hashtext.substring(10);
        } catch (e) {
            console.log('Error encoding data to MD5', e);
        }
        return enc;
    }

    /**
     * Verify signature and decode input <code>enc</code> string.
     *
     * @param enc the encoded Hex string to verify/decode
     * @return the decoded string, or null if the decoding fails
     */
    /*public static String decode(String enc) {
        int len = enc.length();
        if (len > 32) {
            String data = new String(DatatypeConverter.parseHexBinary(enc.substring(10, 10 + (len - 32)).toUpperCase()));
            String hashIn = enc.substring(0, 10) + enc.substring(10 + (len - 32));

            try {
                String hashCalc = calculateMD5(data);
                if (hashIn.equals(hashCalc)) {
                    return data;
                }
            } catch (NoSuchAlgorithmException exc) {
                logger.error("MD5 algorithm not found", exc);
            } catch (Exception exc) {
                logger.error("Error decoding MD5 data", exc);
            }
        }
        return null;
    }*/

    /**
     * Generate a random alphanumeric string of given length
     *
     * @param length of the generated random string
     * @return the generated random string
     */
    /*public generateRandomAlphanumericString(targetStringLength: number) {
        const leftLimit = 48; // numeral '0'
        const rightLimit = 122; // letter 'z'

        return random.ints(leftLimit, rightLimit + 1)
        .filter(i -> (i <= 57 || i >= 65) && (i <= 90 || i >= 97))
        .limit(targetStringLength)
        .collect(StringBuilder::new, StringBuilder::appendCodePoint, StringBuilder::append)
        .toString();
    }*/

    public strToBytes(str) {
        const utf8 = unescape(encodeURIComponent(str));

        const arr = [];
        for (let i = 0; i < utf8.length; i++) {
            arr.push(utf8.charCodeAt(i));
        }

        return arr;
    }

    public toHexString(byteArray) {
        return Array.prototype.map.call(byteArray, function(byte: number) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('');
    }

    public toByteArray(hexString) {
        const result = [];
        for (let i = 0; i < hexString.length; i += 2) {
          result.push(parseInt(hexString.substr(i, 2), 16));
        }
        return result;
    }
}
